$primary-bkg-color: #FE7B09;
$primary-hover-color: #EB6200;

$default-bkg-color: #f8d2a1;
$light-bkg-color: #FBECD2;

/* Things I couldnt config in bootstrap customize */

@media (min-width: 1200px){
  .container {
    width: 95%;
    max-width: 1500px;
  }
}

body {
  background-color: $default-bkg-color;
  background: repeating-linear-gradient(-45deg, #f8d2a1, #f8d2a1 8px, #dbb27d 16px, #dbb27d 10px);
}

.btn-default:hover,
.btn-primary:hover {
  background: $primary-hover-color;
  color: white;
}

/* Other styles */

.red {
  color: red;
}

.pagination {
  margin: 0;
}

.paginator-row {
  margin: 10px 0px;

  .form-control {
    background: $default-bkg-color;
  }

  label {
    margin-right: 7px;
  }
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.no-padding {
  padding: 0;
}

.listrow {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.divider-title {
  border-bottom: 2px solid lightblue;
  margin-top: 15px;
  margin-bottom: 25px;
}

header {
  height: 100px;
  padding: 0;
  margin: 25px 0;

  form {
    width: 100%;
  }

  div,
  nav {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .well {
    text-align: right;
    width: 100%;
    a {
      width: 100%;
    }
  }

  .logo {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100px;
  }

  nav {
    ul {
      list-style: none;

      li {
        float: left;
        margin-right: 10px;

        a {
          text-transform: uppercase;
          font-color: black;
          font-weight: 500;
        }
      }
    }
  }
}


.minicart {
  font-size: small;

  .row {
    margin-bottom: 15px;
  }
}

.cart {
  .row {
    font-size: small;
    margin-bottom: 15px;

    div {
      padding: 0px 5px;
      text-align: center;
    }
  }

  .row.header {
    font-size: medium;
    font-weight: bold;
  }
}

.prod-caption {
  h4 {
    height: 50px;
  }
}

#cssmenu {
  width: 100%;

  ul ul li.active > a {
    background-color: #777777;
  }
}

.sidebox {
  margin-bottom: 25px;
}

.product-row {
  border-bottom: 1px solid lightgray;
}

.main-box {
  margin: 20px 0px;
}

h2.product-name {
  margin-top: 0;
}

.hero-feature .image {
  height: 200px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &--horizontal {
    height: 50px;
  }
}

.property-row {
  div {
    border-color: black;
    background-color: $default-bkg-color;
    border-style: solid;
    border-width: 0;
    border-right-width: 1px;
    border-bottom-width: 1px;
    padding: 10px 15px;
  }

  div.first {
    border-top-width: 1px;
  }

  .property {
    border-left-width: 1px;
  }
}

.navbar li a,
span.title {
  font-family: 'Open Sans Condensed', sans-serif;
}

footer {
  margin-top: 50px;
}

.main-container {
  min-height: 768px;
  -webkit-border-radius: 12px 0px;
  -moz-border-radius: 12px 0px;
  border-radius: 12px 0px;
}

.navbar {
  border-color: #ff8418;
  background: #ff8418;
  margin-bottom: 25px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  /*background: linear-gradient(to bottom,#fea455 0%,#fea455 49%,#ff8418 51%,#ff8418 100%);
  background: -webkit-linear-gradient(to bottom,#fea455 0%,#fea455 49%,#ff8418 51%,#ff8418 100%);
  background: -moz-linear-gradient(to bottom,#fea455 0%,#fea455 49%,#ff8418 51%,#ff8418 100%);
  background: -ms-linear-gradient(to bottom,#fea455 0%,#fea455 49%,#ff8418 51%,#ff8418 100%);
  background: -o-linear-gradient(to bottom,#fea455 0%,#fea455 49%,#ff8418 51%,#ff8418 100%);*/
}

.navbar li a:hover, .navbar li a.active {
  background-color: $primary-hover-color!important;
}

#cssmenu > ul > li.open > a {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid $primary-bkg-color;
}

#cssmenu > ul > li > a:hover, #cssmenu > ul > li.active > a, #cssmenu > ul > li.open > a {
  color: #eeeeee;
  background: #fe963c;
  background: -webkit-linear-gradient(#fe963c, #fe8922);
  background: -moz-linear-gradient(#fe963c, #fe8922);
  background: -o-linear-gradient(#fe963c, #fe8922);
  background: -ms-linear-gradient(#fe963c, #fe8922);
  background: linear-gradient(#fe963c, #fe8922);
}

#cssmenu > ul > li > a {
  padding: 15px 20px;
  border-left: 1px solid $primary-bkg-color;
  border-right: 1px solid $primary-bkg-color;
  border-top: 1px solid $primary-bkg-color;
  cursor: pointer;
  z-index: 2;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  background: #fea455;
  background: -webkit-linear-gradient(#fea455, #fe963c);
  background: -moz-linear-gradient(#fea455, #fe963c);
  background: -o-linear-gradient(#fea455, #fe963c);
  background: -ms-linear-gradient(#fea455, #fe963c);
  background: linear-gradient(#fea455, #fe963c);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
}

header {
  background: transparent;

  div.row {
    width: 100%;
  }
}

.bx-wrapper {
  border: 5px solid black;
}

.main-container {
  background: $light-bkg-color;
  padding-top: 50px;
}

.navbar {
  border-color: black;
  background: #fea455;

  li a {
    color: black!important;
    font-family: 'PT Sans', sans-serif;
    text-transform: none;
  }

  li a:hover {
    color: white!important;
  }
}

footer .copyright {
  background: $light-bkg-color;
  color: black;
}

span.title {
  color: black;
  border-color: #fea455;
  font-family: 'PT Sans', sans-serif;
  font-size: 19px;
}

#cssmenu ul ul li a {
  background: $default-bkg-color;
  color: black;
  border-color: $primary-bkg-color;
}

#cssmenu ul ul li:hover > a,
#cssmenu ul ul li.open > a,
#cssmenu ul ul li.active > a,
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background: $primary-hover-color;
}

#header-search-form {
  input {
    background-color: $light-bkg-color;
  }

  .input-group-btn .btn {
    background-color: #fea455;
    border-color: #ccc;
  }
}

.checkout-form .form-control {
  background: rgba(255,255,255,.9);
}

.page-title {
  margin-top: 0;
  margin-bottom: 35px;
}